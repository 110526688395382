const Bctranslations = {
  home: { en: "Home", th: "หน้าแรก" },
  corporate: { en: "Corporate Overview", th: "เกี่ยวกับองค์กร" },
  reit: { en: "Reit Overiew", th: "เกี่ยวกับกองทรัสต์" },
  about: { en: "About DreitBB", th: "เกี่ยวกับ DreitBB" },
  structure: { en: "Trust Structure", th: "โครงสร้างกองทรัสต์" },
  investmentpolicy: { en: "Investment Policy", th: "นโยบายการลงทุน" },
  management: { en: "Management", th: "การบริหารจัดการ" },
  manager: { en: "Reit Manager", th: "ผู้จัดการกองทรัสต์" },
  reitstructure: { en: "Reit Structure", th: "โครงสร้างองค์กร" },
  board: { en: "Board of Directors", th: "คณะกรรมการบริษัท" },
  lease: { en: "Lessee and Hotel Management", th: "ผู้เช่าและผู้บริหารโรงแรม" },
  trustee: { en: "Trustee", th: "ทรัสตี" },
  investment: { en: "Investment Properties", th: "สินทรัพย์ที่ลงทุน" },
  chinatown: { en: "Asai Bangkok Chinatown", th: "อาศัย กรุงเทพฯ ไซน่าทาวน์" },
  sathorn: { en: "Asai Bangkok Sathorn", th: "อาศัย กรุงเทพฯ สาทร" },
  performance: {
    en: "Asset Performance",
    th: "ผลการดำเนินงานของอสังหาริมทรัพย์",
  },
  ir: { en: "Investor Relations", th: "นักลงทุนสัมพันธ์" },
  resources: { en: "Investor Resources", th: "แหล่งข้อมูลสำหรับนักลงทุน" },
  faq: { en: "FAQs", th: "คำถามที่พบบ่อย" },
  contactus: { en: "Contact Us", th: "ติดต่อเรา" },
  contactinfo: { en: "Contact Information", th: "ข้อมูลติดต่อ" },
  finance: { en: "Financial Information", th: "ข้อมูลการเงิน" },
  nav: { en: "Unit NAV", th: "ราคา NAV" },
  shareholder: { en: "Shareholder Information", th: "ข้อมูลผู้ถือหุ้น" },
  dividend: { en: "Dividend Information", th: "ข้อมูลการจ่ายเงินปันผล" },
  publications: { en: "Publications", th: "เอกสารเผยแพร่" },
  news: { en: "News Release", th: "ข่าวเผยแพร่" },
  cookie: { en: "Cookie Policy", th: "นโยบายคุกกี้" },
  privacy: { en: "Privacy Policy", th: "นโยบายความเป็นส่วนตัว" },
  term: { en: "Term of Use", th: "เงื่อนไขการใช้งาน" },
};

export default Bctranslations;
